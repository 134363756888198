import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './header/Header';
import Inicio from './inicio/Inicio';
import Nosotros from './nosotros/Nosotros';
import Productos from './productos/Productos';
import Servicios from './servicios/Servicios';
import Contacto from './contacto/Contacto';
import Footer from './footer/Footer';


const App = () => {
  /*
  const externalJS = "../src/ExternalFunction.js";
  const script = document.createElement("script");
  script.src = externalJS;
  document.body.appendChild(script);
  */

  return(
    <div id="sections" className="row-fluid">
      <Header id="header_section"/>
      <Inicio id="inicio_section" />
      <Nosotros id="nosotros_section" />
      <Productos id="productos_section" />
      <Servicios id="servicios_section" />
      <Contacto id="contacto_section" />
      <Footer id="footer_section" />
    </div>
  )
}

export default App;
