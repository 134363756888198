const PostRequestForm = () => {

    const bodyData={
        "name": document.getElementById('form_name').value ,
        "company": document.getElementById('form_company').value ,
        "email": document.getElementById('form_email').value ,
        "telephone": document.getElementById('form_telephone').value ,
        "comment": document.getElementById('form_comment').value
    };

    const requestSetUp = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'/* ,
            'Authorization': 'Bearer my-token',*/
        }, 
        body: JSON.stringify(bodyData)
    };
    fetch('ADD ENDPOINT HERE', requestSetUp).then(response => response.json())
};

export default PostRequestForm ;