import React from 'react';
import '../App.css';
import { Modal } from 'react-bootstrap'
import { useState } from "react";

import Carousel from 'react-bootstrap/Carousel'
import Chiles from '../images/chiles.jpg';
import Flavors from '../images/flavors.jpeg';
import Frutas from '../images/frutas.jpg';
import Frutas2 from '../images/frutas2.jpg';
import Frutillas from '../images/frutillas.jpg';
import Lacteos from '../images/lacteos.jpg';
import Pan from '../images/pan.jpg';
import Quesos from '../images/quesos.jpg';
import Seltzer from '../images/seltzer.jpg';
import Smootie from '../images/smootie.jpg';

import PDF from "../productos_iqidi.pdf";

import {Button} from 'react-bootstrap';



const Productos = (parameters) => {
    const contentQueso={
        id:"content_queso",
        class_modal_header:"modalHeaderQueso", id_modal_body:"modal_body_queso", class_modal_body: "bodyStyle bodyStyleQueso",
        images:[Quesos,Lacteos],
        button_label:"QUESOS", title: "Quesos",
        subtitle: "Tenemos una gran variedad de sabores de quesos:",
        lista: ["QUESO OAXACA","QUESO CHEDDAR","QUESO ASADERO","QUESO PANELA","QUESO MANCHEGO","QUESO FRESCO","QUESO DE CABRA","QUESO GOUDA","QUESO COTIJA","QUESO RANCHERO"]
    };

    const contentFruta={
        id:"content_fruta",
        class_modal_header:"modalHeaderFruta", id_modal_body:"modal_body_fruta", class_modal_body: "bodyStyle bodyStyleFruta",
        images:[Frutas,Frutas2],
        button_label: "FRUTALES",title: "Frutales",
        subtitle: "Tenemos una gran variedad de sabores frutales:",
        lista: ["FRESA","MANGO","DURAZNO","MANDARINA","NARANJA","MANZANA VERDE","MANZANA ROJA","UVA","PIÑA","CEREZA","GROSELLA","LIMÓN","TORONJA"]
    };

    const contentLacteo={
        id:"content_lacteo",
        class_modal_header:"modalHeaderLacteo", id_modal_body:"modal_body_lacteo", class_modal_body: "bodyStyle bodyStyleLacteo",
        images:[Lacteos,Smootie],
        button_label: "LÁCTEOS",title: "Lácteos",
        subtitle: "Tenemos una gran variedad de sabores lácteos:",
        lista: ["LECHE","LECHE EVAPORADA","LECHE CONDENSADA","CREMA DULCE","CREMA ÁCIDA","MANTEQUILLA","YOGURTH"]
    };

    const contentPan={
        id:"content_pan",
        class_modal_header:"modalHeaderPan", id_modal_body:"modal_body_pan", class_modal_body: "bodyStyle bodyStylePan",
        images:[Pan,Frutillas],
        button_label: "PANADERÍA",title: "Panadería",
        subtitle: "Tenemos una gran variedad de sabores de panificación:",
        lista: ["NARANJA","NUEZ","MANTEQUILLA","MANTEQUILLA NARANJA","PAN DE MUERTO","ROSCA DE REYES","HUEVO","CHOCOLATE","VAINILLA FRANCESA","VAINILLA","AJONJOLÍ"]
    };

    const contentChile={
        id:"content_chile",
        class_modal_header:"modalHeaderChile", id_modal_body:"modal_body_chile", class_modal_body: "bodyStyle bodyStyleChile",
        images:[Chiles,Flavors],
        button_label: "CHILES",title: "Chiles",
        subtitle: "Tenemos una gran variedad de sabores potenciadores de chiles:",
        lista: ["HABANERO","MANZANO","PASILLA","JALAPEÑO","CHILTEPIN","MORITA","PIQUIN","SERRANO","CHIPOTLE","MOLE"]
    };

    const contentSeltzer={
        id:"content_seltzer",
        class_modal_header:"modalHeaderSeltzer", id_modal_body:"modal_body_seltzer", class_modal_body: "bodyStyle bodyStyleSeltzer",
        images:[Seltzer,Smootie],
        button_label: "SELTZER",title: "Seltzer",
        subtitle: "Tenemos una gran variedad de sabores seltzer:",
        lista: ["MANDARINA","TORONJA","TAMARINDO","MANGO","MARACUYA","LIMONADA","NARANJADA"]
    };
    


    return(
        <div id={parameters.id} className="mr-auto ml-auto">

            <div id="productos_content_text"className="col-12 col-md-11 mr-auto ml-auto text-center">
                <h1 className="sectionTitle">Productos IQ+IDI</h1>
                {/*
                <h4 className="sectionSubtitle">En IQIDI contamos con una amplia variedad de saborizantes para todo tipo de propositos, todos ellos apropiados para los paladares más exigentes.</h4>
                <h3 className="productosExclamation">¡Si puedes imaginarlo, nosotros podemos crearlo!</h3>
                */}
            </div>

            <div className="containerProductImg col-12 mt-4">
                <ProductoGenerico content={contentQueso}></ProductoGenerico>
                <ProductoGenerico content={contentFruta}></ProductoGenerico>
                <ProductoGenerico content={contentChile}></ProductoGenerico>
            </div>
            <div className="containerProductImg col-12 mt-4">
                <ProductoGenerico content={contentLacteo}></ProductoGenerico>
                <ProductoGenerico content={contentPan}></ProductoGenerico>
                <ProductoGenerico content={contentSeltzer}></ProductoGenerico>
            </div>
            
            <div className="text-center mr-3 pb-4">
                <Button className="buttonStd2" download="Productos_iqidi.pdf" href={PDF}>
                    DESCARGAR LISTA DE PRODUCTOS
                </Button>
            </div>

        </div>
    )
}

const ProductoGenerico = (p) => {
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    function handleShow() {
        setFullscreen(true);
        setShow(true);
    }

    return(

        [p].map( () => { 
            return(
                
                <div id={p.content.id} className="boxProductImg col-4 mb-4">
                    <img src={p.content.images[0]} alt={p.content.button_label} key={p.content.id} onClick={() => handleShow()}></img>
                    <span>{p.content.button_label}</span>
                    

                    <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                        <Modal.Header closeButton className={p.content.class_modal_header}>
                            <Modal.Title className="modalProductTitle">{p.content.title}</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <div id={p.content.id_modal_body} className="row mb-0 pb-0">
                                {/*<p className="modalProductSubtitle">{p.content.subtitle}</p>*/}
                                
                                <div id="modal_product_images" className="col-12 col-sm-7 col-md-8 col-xl-9 ml-auto mr-auto mb-1">
                                    <CarouselGeneric parameters={p.content.images}>
                                    </CarouselGeneric>
                                </div>
                                
                                <div id="modal_product_list" className="col-12 col-sm-5 col-md-4 col-xl-3 ml-auto mr-auto pt-3 pb-3">
                                    { 
                                    
                                    [...p.content.lista].map( (item) => {
                                        let lastElement = p.content.lista[p.content.lista.length - 1];
                                        if(item === lastElement) {
                                            return(
                                                <div className="modalProductItem row ml-auto mr-auto">
                                                    <div></div>
                                                    {item}
                                                </div>
                                            )
                                        }
                                        else{
                                            return(
                                                <>
                                                <div className="modalProductItem row ml-auto mr-auto">
                                                    <div></div>
                                                    {item}
                                                </div>
                                                <hr/>
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                            
                            <hr/>
                             
                            <div className="col-12 ml-auto mr-auto mt-0">
                                <ul className="list-unstyled text-center row mb-0">
                                    <div className="footerModalItem footerText ml-auto mr-auto col-12 col-sm-10 col-md-3">
                                        <div className="footerAddressItem text-center"></div>
                                        Santiago de Querétaro, Querétaro, México.
                                    </div>
                                
                                    <div className="footerModalItem footerText ml-auto mr-auto col-12 col-sm-10 col-md-3">
                                        <div className="footerTelephoneItem text-center"></div>
                                        (+52) 44 2325 9526
                                    </div>
                                    <div className="footerModalItem footerText ml-auto mr-auto col-12 col-sm-10 col-md-3">
                                        <div className="footerEmailItem text-center"></div>
                                        ventas@iqidi.com.mx
                                    </div>                                    
                                </ul>
                            </div>
                            
                        </Modal.Body>
                        
                    </Modal>
                </div>

            )
        } )

    )
}

const CarouselGeneric = (p) => {
    return(
        <Carousel fade className="imageCarouselBox flex">
            {
            [...p.parameters].map( (item) => {
                return(
                    <Carousel.Item interval={2000} className="imageCarouselCont">
                        <img className="imageCarousel70 radiusX" src={item} alt="First slide"/>
                    </Carousel.Item>
                    
                )
            })
            }
        </Carousel>
    )
}

export default Productos;