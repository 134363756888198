import React from 'react';
import '../App.css';
import Img1 from "../images/definicion.png"
import Img2 from "../images/diseno.png"
import Img3 from "../images/implementacion.png"
import Img4 from "../images/produccion.png"

const ServicioItem = (p) =>{
    return(
        <div className="circleDiv ml-auto mr-auto col-6 col-md-3 mb-3">
            <img src={p.parameters.image} alt=""></img>
            <p>{p.parameters.text}</p>
        </div>
    )
}


const Servicios = (parameters) => {
    const contentService1={ image: Img1, text: "Definición" };
    const contentService2={ image: Img2, text: "Diseño" };
    const contentService3={ image: Img3, text: "Implementación" };
    const contentService4={ image: Img4, text: "Producción" };

    return(
        <div id={parameters.id} className="col-12 mr-auto ml-auto">
            <div id="servicios_content_text"className="col-12 col-md-11 mr-auto ml-auto text-center">
                <h1 className="sectionTitle mb-4">Servicios IQ+IDI</h1>
                <h4 className="sectionSubtitle mb-4">En IQ+IDI proporcionamos una asesoria integral en el desarrollo de proyectos, desde la ejecución en laboratotio como en la producción industrial para darle una solución a tus necesidades.</h4>
            </div>

            <div className="row ml-auto mr-auto text-center">
                <ServicioItem parameters={contentService1}/>
                <ServicioItem parameters={contentService2}/>
                <ServicioItem parameters={contentService3}/>
                <ServicioItem parameters={contentService4}/>
            </div>
        </div>
    )
}


export default Servicios;