import React from 'react';
import '../App.css';
import FacebookLogo from '../images/t_facebook.png';
import InstagramLogo from '../images/t_instagram.png';
import TwitterLogo from '../images/t_twitter.png';

const Footer = (parameters) => {
    return(
        <div id={parameters.id} className="col-12">
            <footer className=" text-center ">

                <section className="mb-3 text-center">
                    <a href="https://www.lipsum.com/" className="me-4 text-reset">
                        <img src={FacebookLogo} alt="iqidi_facebook" className="contactoFooterLogo"/>
                    </a>
                    <a href="https://www.lipsum.com/" className="me-4 text-reset">
                        <img src={InstagramLogo} alt="iqidi_instagram" className="contactoFooterLogo"/>
                    </a>
                    <a href="https://www.lipsum.com/" className="me-4 text-reset">
                        <img src={TwitterLogo} alt="iqidi_twitter" className="contactoFooterLogo"/>
                    </a>
                </section>


                <section className="">
                    <form action="">
                        <div className="row d-flex justify-content-center">
                            <div className="col-auto mt-2">
                                <p className="footerSubtitle">Subscribete al newsletter:</p>
                            </div>

                            <div className="col-md-5 col-10 mb-2">
                                <div className="form-outline">
                                    <input type="email" placeholder="Email" id="form_newsletter_email" className="form-control" required/>
                                </div>
                            </div>

                            <div className="col-auto">
                                <button type="submit" className="btn btn-primary buttonStd1">
                                SUBSCRIBIRME
                                </button>
                            </div>
                        </div>
                    </form>
                </section>

                <hr/>

                <section className="">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-12 mb-md-0 ml-auto mr-auto">
                            <h5 className="text-uppercase footer_title mb-2">IQ+IDI</h5>
                            <ul className="list-unstyled text-left">
                                    <p className="footerText mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce iaculis vestibulum ipsum eget pellentesque. Mauris in finibus neque. Vivamus tortor mauris, aliquet eu dui id, porttitor ornare eros. Cras a dolor sit amet metus feugiat congue. Maecenas eget suscipit metus.</p>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-3 col-12 mb-md-0 ml-auto mr-auto">
                            <h5 className="text-uppercase footer_title mb-2">Productos y Servicios</h5>

                            <ul className="list-unstyled text-left">
                                    <p className="footerText mb-0">Text 1</p>
                    
                                    <p className="footerText mb-0">Text 2</p>
                             
                                    <p className="footerText mb-0">Text 3</p>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4 col-12 mb-md-0 ml-auto mr-auto">
                            <h5 className="text-uppercase footer_title mb-2">Contacto</h5>

                            <ul className="list-unstyled text-left">
                                
                                    <div className="footerItem footerText mb-0 ml-auto mr-auto mb-2">
                                        <div className="footerAddressItem"></div>
                                        Santiago de Querétaro, Querétaro, México.
                                    </div>
                                
                                        <div className="footerItem footerText mb-0 ml-auto mr-auto mb-2">
                                        <div className="footerTelephoneItem"></div>
                                        (+52) 44 2325 9526
                                    </div>
                               
                                    <div className="footerItem footerText mb-0 ml-auto mr-auto ">
                                        <div className="footerEmailItem"></div>
                                        ventas@iqidi.com.mx
                                    </div>
                                
                                
                            </ul>
                        </div>
                    </div>
                </section>

                

                <hr/>

                <div className="text-center footerCopyright">
                    © 2020 Copyright: IQIDI
                </div>
            </footer>
        </div>
    )
}


export default Footer;