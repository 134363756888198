import React from 'react';
import '../App.css';
import {Form, Button} from 'react-bootstrap';
import PostRequestForm from "../PostRequestForm.js"

const Contacto = (parameters) => {
    return(
        <div id={parameters.id}>
            <div id="contacto_content" className="col-12 col-sm-12 col-md-9 col-lg-7">
                <div id="contacto_text" className="col-12 mr-auto ml-auto" >
                    <h1 className="sectionTitle">Contactanos</h1>
                    <h4 className="sectionSubtitle text-center">Dejanos saber lo que piensas.</h4>
                </div>
                <ContactoForm id="contacto_form"></ContactoForm>
            </div>
        </div>
    )
}

const ContactoForm = (parameters) => {
    return(
        <div id={parameters.id} className="col-12 col-sm-12 col-md-10 col-lg-8 mr-auto ml-auto">
            <Form >                
                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Nombre*" id="form_name" required/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Empresa" id="form_company"/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email*" id="form_email" required/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control type="text" placeholder="Teléfono*" id="form_telephone" required/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control as="textarea" placeholder="Escribe aqui tu comentario" id="form_comment" rows={3} />
                </Form.Group>

                <Form.Group className="mb-3 text-right">
                    <Button type="submit" className="buttonStd2" id="form_submit" onClick={() => PostRequestForm()}>
                        ENVIAR
                    </Button>
                </Form.Group>

                <Form.Group controlId="formLabelPrivacy">
                    <Form.Label className="privacityText">
                        Tu información de contacto no será compartida y será utilizada unicamente con la finalidad del mantenimiento de la relación comercial y el desempeño de tareas de información, formación, asesoramiento y otras actividades propias de nuestros productos.
                    </Form.Label>
                </Form.Group>

            </Form>
        </div>
    )
}

export default Contacto;