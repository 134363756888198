import React from 'react';
import '../App.css';
import Mision from "../images/frutillas.jpg"
import Vision from "../images/mezcla.jpg"
import Valores from "../images/lab.jpg"

const CardMVV = (p) => {
    return(
        <div className="boxImageNMV col-12 col-md-4 mb-3">
            <img src={p.parameters.image} alt="sample45" />
            <figcaption>
                <h3>{p.parameters.title}</h3>
                <p>{p.parameters.text}</p>
            </figcaption>
        </div>
    )
}

const Nosotros = (parameters) => {
    const contentMision={ image: Mision, title: "Misión", text: "Agregar aquí todo el texto de misión, visión o valores." };
    const contentVision={ image: Vision, title: "Visión", text: "Agregar aquí todo el texto de misión, visión o valores." };
    const contentValores={ image: Valores, title: "Valores", text: "Agregar aquí todo el texto de misión, visión o valores." };

    return(
        <div id={parameters.id} className="col-12 mr-auto ml-auto">
            <div id="nosotros_header"className="col-12 col-md-11 mr-auto ml-auto text-center">
                <h1 className="sectionTitle mb-4">Nosotros</h1>
                <h4 className="sectionSubtitle mb-4">IQ+IDI Es una empresa 100% Mexicana especializada en la Creación de Sabores. Aliados Tecnológico de nuestros Clientes: Investigamos, Desarrollamos e Innovamos soluciones para la Industria.</h4>
            </div>
            <div id="nosotros_body" className="col-12 row ml-auto mr-auto text-center">
                <CardMVV parameters={contentMision}></CardMVV>
                <CardMVV parameters={contentVision}></CardMVV>
                <CardMVV parameters={contentValores}></CardMVV>
            </div>
        </div>
    )
}

export default Nosotros;