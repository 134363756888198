import React from 'react';
import '../App.css';
import {Button} from 'react-bootstrap';
import Mezcla from '../images/mezcla.jpg';

const Inicio = (parameters) => { 
    return(
        <div id={parameters.id} className="row mr-auto ml-auto">
            <div id="inicio_content_text" className="col-12 col-md-6 mr-auto">
                <div className="col-12 col-md-8 mr-auto ml-auto text-center">
                    <h1 className="inicioTitle">Investigación,<br/>Desarrollo e<br/>Innovación</h1>
                    <h4 className="inicioSubtitle">Somos una Empresa Mexicana especializada en la Creación de Sabores, nos concebimos como un Aliado Tecnológico de nuestros Clientes, es decir, Investigamos, Desarrollamos e Innovamos soluciones para la Industria desde un enfoque empático.</h4>
                    {/*
                    <Button className="buttonStd1">
                        INICIAR RECORRIDO
                    </Button>
                    */}
                </div>
            </div>
            
            <div id="inicio_content_img" className="col-11 col-md-6 mr-auto ml-auto">
                <div className="container">
                    <div className="cardASD">
                        <div className="cardASD--image">
                            <img src={Mezcla} alt="info"/>
                        </div>

                        <div className="cardASD--info info--1">
                            <span className="info--text">INVESTIGACIÓN</span>
                            <div className="icon">
                                <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div className="info--image">
                                <img src={Mezcla} alt="info"/>
                            </div>
                        </div>
                        <div className="cardASD--info info--2">
                            <span className="info--text">DESARROLLO</span>
                            <div className="icon">
                                <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div className="info--image">
                                <img src={Mezcla} alt="info"/>
                            </div>
                        </div>
                        <div className="cardASD--info info--3">
                            <span className="info--text">INNOVACIÓN</span>
                            <div className="icon">
                                <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div className="info--image">
                                <img src={Mezcla} alt="info"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Inicio;