import React from 'react';
import '../App.css';
import {Navbar, Nav} from 'react-bootstrap';
import logo from '../images/logo.jpeg';

const inactiveLinks = () => {
    for (let n = 1; n <= 4; n++) 
        document.getElementById("l"+n.toString()).classList.remove("active");
}

const Header = () => {
    return(
        <Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="light" className="headerNavbar">
                <Navbar.Brand href="#sections" className="headerBrand linkNavM text-center mt-auto mb-auto" onClick={inactiveLinks}>
                    <img src={logo} height="70px" className="navImage mt-auto mb-auto" alt="IQUDI"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="headerToggle"/>

                    <Navbar.Collapse id="responsive-navbar-nav" className="headerCollapse">
                        <Nav className="ml-auto">
                            <Nav.Link href="#nosotros_section" className="linkNav" id="l1">NOSOTROS</Nav.Link>
                            <Nav.Link href="#productos_section" className="linkNav" id="l2">PRODUCTOS</Nav.Link>
                            <Nav.Link href="#servicios_section" className="linkNav" id="l3">SERVICIOS</Nav.Link>
                            <Nav.Link href="#contacto_section" className="linkNav" id="l4">CONTACTO</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
        </Navbar>
    )
}
  
  export default Header;